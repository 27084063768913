import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderVideo from "../../../components/header/bigHeader/bigHeaderVideo"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logo-bje.svg"
import ogimage from "../../../images/projekte/bje-smarter-gallery/bje-smart-gallery-hero-img.jpg"
import Kontakt from "../../../components/kontakt"
import Navigation from '../../../components/projekte/navigation'
import {AnchorLink} from "gatsby-plugin-anchor-links"
import ThumbleisteSwiper from "../../../components/thumbleisteSwiper"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import myLocalVid from '../../../images/projekte/bje-smarter-gallery/Smarter-Gallery-Headervideo_V2-komp.mp4'
import * as projekteStyles from "../projekte.module.css"
import Specs from "../../../components/specs"


const BuschJaegerSmarterGallery = function (props, ref) {

    const data = useStaticQuery(graphql`
        query BuschJaegerSmarterGalleryQuery {
            heropic: file(relativePath: { eq: "images/projekte/bje-smarter-gallery/bje-smart-gallery-hero-img.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                      placeholder: BLURRED,
                      layout: FULL_WIDTH,
                      aspectRatio: 1.7,
                    )
                  }
            }

            ogimage: file(relativePath: { eq: "images/projekte/bje-smarter-gallery/bje-smart-gallery-hero-img.jpg" }) {
                childImageSharp {
                    fixed(height: 100) {
                        src
                        height
                        width
                    }
                }
            }

            allFile(filter: {extension: {regex: "/(jpg|png)/"}, relativeDirectory: {eq: "images/projekte/bje-smarter-gallery/thumbleiste"}} sort: {fields: name}) {
                edges {
                  node {
                    id
                    childImageSharp {
                        picscropped:gatsbyImageData(placeholder: DOMINANT_COLOR, aspectRatio: 1, layout: FULL_WIDTH, formats: [WEBP], transformOptions: {rotate: 0, cropFocus: CENTER})
                        pics:gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH, formats: [WEBP])
                      }
                  }
                }
            }
        }
    `)
    
    const specsData = [
        { title: "Eckdaten", objects: [logo, "Web App"] },
        { title: "Leistungen", objects: ["konzept", "design", "programmierung"] },
        {
          title: "Toolchain",
          objects: ["sketch", "html", "css", "js", "react"],
        },
      ]

    return (
        <Layout>
            <SEO
                title="Busch-Jaeger Smarter Gallery"
                description="Q:marketing realisiert digitale Tools für den Showroom von Busch-Jaeger."
                image={data?.ogimage?.childImageSharp?.fixed}
            />

            <BigHeaderVideo mode="textFirst" className={`${projekteStyles.child}`} bgVideo={myLocalVid} videoMode="local" posterImage="">                
                <div className="row py-5 py-md-0">
                    <div className="col-12 col-md-5">
                        <img src={logo} className="h-40px" alt=""/>
                        <h1>Tools für die Smarter Gallery</h1>                                         
                        <p className="introtext">Buchungssystem und NFC Scanner App für den Showroom von Busch-Jaeger in Köln.</p>
                        <AnchorLink to="/projekte/sales-apps/smarter-gallery/#thumbleiste" title="↓ Mehr Erfahren" className="q-btn q-btn-primary" />
                    </div>
                    <div className="col-7"></div>
                </div>
            </BigHeaderVideo>                                                              
                               
            {/* Bilderstrecke Thumbs */}   
            <ThumbleisteSwiper picdata={data.allFile} bgCol="#EFEFE6"/>    

            {/* Reasons why */}
            <ListenModule headline="Reasons why">
                <Liste
                    items={[                        
                        "Buchungstool zeigt verfügbare Termine auf einen Blick und macht die Reservierung schnell und einfach.",
                        "Automatisierte Zutrittskontrolle zum Showroom durch generierten Code.",
                        "Begleitende Web-App liefert Produktinformationen zum Mitnehmen per NFC-Tag oder QR-Code."
                    ]}
                />
            </ListenModule>
            
            <Specs data={specsData} />
            
            {/* navigation */}            
            <Navigation location={props.location} />

            {/* kontakt */}            
            <Kontakt ansprechpartner="juergen-dworak"/>

        </Layout>
    )
}

export default BuschJaegerSmarterGallery